import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  authenticatorComponents,
  authenticatorFormFields,
} from "./components/Authenticator";
import Dashboard from "./components/Dashboard";
import {
  AppBar,
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";
import React from "react";

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT,
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ["phone", "email", "profile", "openid"],
      responseType: "code",
    },
  },
});

let mdTheme = createTheme();
mdTheme = responsiveFontSizes(mdTheme);

export default function App() {
  return (
    <Authenticator
      loginMechanisms={["email"]}
      hideSignUp={true}
      formFields={authenticatorFormFields}
      components={authenticatorComponents}
    >
      {({ signOut, user }) => (
        <React.Fragment>
          <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <AppBar position="absolute">
                <Toolbar>
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                  >
                    Novelty Analysis Dashboard
                  </Typography>
                  <Typography
                    component="sub"
                    color="inherit"
                    noWrap
                    sx={{ m: 2 }}
                  >
                    {user?.username}
                  </Typography>
                  <Button onClick={signOut} color="inherit" variant="outlined">
                    Logout
                  </Button>
                </Toolbar>
              </AppBar>
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Toolbar />
                <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "85vh",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Dashboard />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          </ThemeProvider>
        </React.Fragment>
      )}
    </Authenticator>
  );
}
