import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { CircularProgress, Typography } from "@mui/material";

const currentIdToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

async function getDashboardUrl(idToken: string) {
  return await fetch(
    `${process.env.REACT_APP_RESTURL}/quicksight?${new URLSearchParams({
      sessionLifetime: "15",
    }).toString()}`,
    {
      headers: {
        Authorization: idToken || "",
      },
      method: "GET",
    }
  );
}

export default function Dashboard() {
  const [dashboardUrl, setDashboardUrl] = useState<string>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [isMaintenance, setMaintenance] = useState<boolean>(false);

  const fetchDashboardData = async () => {
    try {
      setLoadingData(true);
      setDashboardUrl(undefined);
      const response = await getDashboardUrl(await currentIdToken()).then(
        (response) => response.json()
      );

      const { dashboardUrl, maintenance } = response;
      if (maintenance) {
        setMaintenance(true);
      } else setDashboardUrl(dashboardUrl);
    } catch (err) {
      console.error(err);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <React.Fragment>
      {isMaintenance ? (
        <Typography variant="h4">
          Aufgrund von Wartungsarbeiten steht dieses Dashboard aktuell nicht zur
          Verfügung.
        </Typography>
      ) : loadingData || !dashboardUrl ? (
        <CircularProgress size={60} />
      ) : (
        <Iframe url={dashboardUrl} height="100%" width="100%" frameBorder={0} />
      )}
    </React.Fragment>
  );
}
