import { useTheme, Heading, View, Image } from "@aws-amplify/ui-react";
import React from "react";

function MANLogo() {
  const { tokens } = useTheme();
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image alt="MAN Logo" src="/logo.svg" height="50%" width="50%" />
    </View>
  );
}

export const authenticatorComponents = {
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 5 ${tokens.space.xl}`}
          level={3}
        >
          MFA einrichten
        </Heading>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <React.Fragment>
          <MANLogo />
          <Heading
            padding={`${tokens.space.xl} 0 6 ${tokens.space.xl}`}
            level={6}
          >
            Bitte geben Sie ihren MFA Token aus der App ein
          </Heading>
        </React.Fragment>
      );
    },
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <React.Fragment>
          <MANLogo />
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Anmelden
          </Heading>
        </React.Fragment>
      );
    },
  },
};

export const authenticatorFormFields = {
  setupTOTP: {
    QR: {
      totpIssuer: "MAN Novelty Dashboard",
    },
    confirmation_code: {
      label: "Code",
      placeholder: "Bitte geben Sie hier den generierten Code ein",
      isRequired: true,
    },
  },
  signIn: {
    username: {
      label: "E-Mail",
      placeholder: "E-Mail Adresse eingeben",
    },
    password: {
      label: "Passwort",
      placeholder: "Passwort eingeben",
    },
  },
  forceNewPassword: {
    password: {
      label: "Passwort",
      placeholder: "Bitte geben Sie ihr neues Passwort ein",
    },
    confirm_password: {
      label: "Passwort bestätigen",
      placeholder: "Bitte geben Sie ihr neues Passwort erneut ein",
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "MFA Token",
      placeholder: "MFA Token eingeben",
    },
  },
};
